import React, { useState } from 'react';
import './css/App.css';

function TextoFondoRepe() {

const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

const handleResize = () => {
    setIsResponsive(window.innerWidth <= 768);
};

window.addEventListener('resize', handleResize);

return (
    <>
        {isResponsive ? (
            <>
                <img className='imagenList1' src='/images/Serv1.png' alt=''/>
                <img className='imagenList2' src='/images/Serv2.png' alt=''/>
            </>
        ) : (
            <img className='fondo-text2FONDO' src='/images/ServRecorte.png' alt=''/>
            // <div className='fondo-text2FONDO'>
            // </div>
        )}
    </>
);
}

export default TextoFondoRepe;