import React from 'react';
import './css/App.css';

function TextoFormulario() {
    return (
        <div>
            <p className='textFormulario'>
                <span class="firstLine">¡Obtenga el asesoramiento que su negocio necesita!</span> <br/>
                Comuniquese con nosotros por el medio que elija <br/>
                y comience hoy a mejorar la limpieza <br/>
                de su lugar de trabajo. <br/>
            </p>
        </div>

    );
}

export default TextoFormulario;