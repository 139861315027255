import React from 'react';
import './css/App.css';

function ImagenContacto() {
    return (
        <div className='footerDiv'>
            <div className='logoContacto'>
                <img
                    src = '/images/logoFooter.svg'
                    alt=''
                    className='imagenContacto' />
                <h1 className='footerLogoTexto'>Aromas</h1>
            </div>
            <div className='contacto-textos'>
                <h3 className='footerLogoTextoH2'>Servicio Integral de Limpieza</h3>
                <p className='footerTextContacto'>
                    Tel: 358 401-2034 <br/>
                    E-mail: ventas@aromaslimpieza.ar <br/>
                    Dirección: Dean Funes 387 - Río Cuarto <br/>
                </p>
            </div>
        </div>
        
    );
}

export default ImagenContacto;