import React, { useState } from 'react';
import Modal from './Modal';
import Modal2 from './Modal2';
import Modal3 from './Modal3';
import Modal4 from './Modal4';
import Modal5 from './Modal5';
import Modal6 from './Modal6';
import './css/App.css';

function Modales() {
    const [modal, setModal] = useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };

    const [modal1, setModal1] = useState(false);
    const toggleModal1 = () => {
        setModal1(!modal1);
    };

    const [modal2, setModal2] = useState(false);
    const toggleModal2 = () => {
        setModal2(!modal2);
    };

    const [modal3, setModal3] = useState(false);
    const toggleModal3 = () => {
        setModal3(!modal3);
    };

    const [modal4, setModal4] = useState(false);
    const toggleModal4 = () => {
        setModal4(!modal4);
    };

    const [modal5, setModal5] = useState(false);
    const toggleModal5 = () => {
        setModal5(!modal5);
    };

    return (
        <>
            <div className='modales'>
                <div>
                    <button onClick={toggleModal} className='btn-WSP'>
                        <img
                            src = '/images/modal1.jpg'
                            alt=''
                            className='img-modalsEMP' />
                    </button>
                    <h3 className='text-textModales'>
                        EMPRESAS Y OFICINAS
                    </h3>
                </div>
                {modal && (<Modal closeModal={toggleModal} />)}
                <div>
                    <button onClick={toggleModal1} className='btn-WSP'>
                        <img
                            src = '/images/modal2.jpg'
                            alt=''
                            className='img-modals' />
                    </button>
                    <h3 className='text-textModales'>
                        CENTROS DE SALUD
                    </h3>
                </div>
                {modal1 && (<Modal2 closeModal={toggleModal1} />)}
                <div>
                    <button onClick={toggleModal2} className='btn-WSP'>
                        <img
                            src = '/images/modal3.jpg'
                            alt=''
                            className='img-modalsEDIF' />
                    </button>
                    <h3 className='text-textModales'>
                        EDIFICIOS Y FINALES DE OBRA
                    </h3>
                </div>
                {modal2 && (<Modal3 closeModal={toggleModal2} />)}
                <div>
                    <button onClick={toggleModal3} className='btn-WSP'>
                        <img
                            src = '/images/modal4.jpg'
                            alt=''
                            className='img-modalsCENTER' />
                    </button>
                    <h3 className='text-textModales'>
                        INSTITUCIONES EDUCATIVAS
                    </h3>
                </div>
                {modal3 && (<Modal4 closeModal={toggleModal3} />)}
                <div>
                    <button onClick={toggleModal4} className='btn-WSP'>
                        <img
                            src = '/images/modal5.jpg'
                            alt=''
                            className='img-modalsBANK' />
                    </button>
                    <h3 className='text-textModales'>
                        ENTIDADES BANCARIAS
                    </h3>
                </div>
                {modal4 && (<Modal5 closeModal={toggleModal4} />)}
                <div>
                    <button onClick={toggleModal5} className='btn-WSP'>
                        <img
                            src = '/images/modal6.jpg'
                            alt=''
                            className='img-modals' />
                    </button>
                    <h3 className='text-textModales'>
                        SEGURIDAD PRIVADA
                    </h3>
                </div>
                {modal5 && (<Modal6 closeModal={toggleModal5} />)}
            </div>
        </>
        
    );
}

export default Modales;