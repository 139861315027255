import React from 'react';
import './css/App.css';

function Marcas() {
    return (
        <>
            <div className='cont-marcas1'>
                <h3 className='text-marcas'>Acompañando a grandes empresas</h3>
                <img
                    src = '/images/marcasLogos.svg'
                    alt=''
                    className='img-logos' />
                
            </div>
            <div className='cont-marcas'>
                <br/>
                <img
                    src = '/images/logoMarcas2.svg'
                    alt=''
                    className='img-logos2' />
            </div>
        </>
    );
}

export default Marcas;