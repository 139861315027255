import React from 'react';
import './css/App.css';

function TextoPrimario() {
    return (
        <div className='fondo-text1'>
            <p className='text-text1'>
                Brindamos soluciones eficientes en función del requerimiento <br/>
                del cliente, tanto en los servicios de limpieza, desinfección <br/>
                y mantenimiento de cada establecimiento, como de seguridad en general. <br/>
                <br/>
                Disponemos de personal idóneo y maquinaria de avanzada <br/>
                tecnología para la ejecución de las tareas a desarrollar.<br/>
                Un ingrediente fundamental es nuestro compromiso permanente, <br/>
                que asegura la calidad de nuestros trabajos y la máxima satisfacción del cliente. <br/>
                <br/>
                Nuestra finalidad es que usted pueda delegar las tareas de limpieza <br/>
                de su empresa como de la seguridad, evitándose de esta manera, <br/>
                una preocupación tan importante como es la de mantener <br/>
                el orden y la higiene diaria en todo su ámbito laboral.
            </p>
        </div>
    );
}

export default TextoPrimario;