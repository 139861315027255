import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BrowserRouter as Router } from 'react-router-dom';
import Carousel from './Carousel';
import Home from './Home';
import Marcas from './Marcas';
import TextoPrimario from './TextoPrimario';
import VideoTexto from './VideosyTexto';
import Modales from './Modales';
import TextoFondoRepe from './TextoFondoRepe';
import CollapsibleText from './TextExpand';
import TextoFormulario from './TextoFormulario';
import Mapa from './Mapa';
import ImagenContacto from './ImagenContacto';
import ModalCV from './ModalCV';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/App.css';

function Formulario() {
    const [formularioEnviado, cambiarFormulario] = useState(false);

    const [emailData, setEmailData] = useState({
        apiKey: '52364EB909C88334F9165B309F198EEC2CB316F33F828E96CDCE52FD8A3078FA790873816B9D565F0874CFD145FE896A',
        from: 'federicooses94@gmail.com',
        to: 'administracion@aromaslimpieza.ar',
        subject: 'Contacto Aromas Landing Page',
        bodyHtml: '',
        isTransactional: false
    });

    const construirCuerpoCorreo = (valores) => {
        var cuerpoHtml = '<h1>Información del formulario</h1>';
        cuerpoHtml += '<p><strong>Nombre:</strong> ' + valores.nombre + '</p>';
        cuerpoHtml += '<p><strong>Correo:</strong> ' + valores.correo + '</p>';
        cuerpoHtml += '<p><strong>Empresa:</strong> ' + valores.empresa + '</p>';
        cuerpoHtml += '<p><strong>Teléfono:</strong> ' + valores.telefono + '</p>';
        cuerpoHtml += '<p><strong>Observaciones:</strong> ' + valores.observaciones + '</p>';
        
        return new Promise((resolve, reject) => {
            resolve(cuerpoHtml);
        });
    };
    
    const enviarCorreo = async (valores) => {
        try {
            const cuerpoHtml = await construirCuerpoCorreo(valores);;
            const emailDataToSend = {
                ...emailData,
                bodyHtml: cuerpoHtml
            };
            const response = await fetch('https://api.elasticemail.com/v2/email/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(emailDataToSend).toString()
            });
            
            if (response.ok) {
                console.log('Correo enviado con éxito.');
            } else {
                console.error('Error al enviar el correo:', response.statusText);
            }
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    };

    const [modal, setModal] = useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };

    const handleRedirection = () => {
        window.open('https://wa.me/543584012034', '_blank');
    };

    const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsResponsive(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return (
    <>
        <Router>
            <Home onOpen={toggleModal}/>
        </Router>
        <CollapsibleText/>
        <Marcas/>
        <Carousel/>
        <TextoPrimario/>
        <VideoTexto/>
        <Modales/>
        <TextoFondoRepe/>
        <TextoFormulario/>
        <div className='footer-content'>
        <Formik
            initialValues={{
                nombre: '',
                correo: '',
                empresa: '',
                telefono: '',
                observaciones: ''
            }}
            validate={(valores) => {
                let errores = {};

                if(!valores.nombre){
                    errores.nombre = 'Por favor ingrese un nombre'
                } else  if(!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.nombre)){
                    errores.nombre = 'El nombre solo puede contener letras y espacios'
                }

                if(!valores.correo){
                    errores.correo = 'Por favor ingrese un correo electrónico'
                } else if(!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.correo)){
                    errores.correo = 'Por favor ingrese un correo electrónico válido'
                }

                if(!valores.empresa){
                    errores.empresa = 'Por favor ingrese una empresa'
                }

                if(!valores.telefono){
                    errores.telefono = 'Por favor ingrese un teléfono'
                } else  if(!/^\d+$/.test(valores.telefono)){
                    errores.telefono = 'El teléfono solo puede tener números'
                }

                return errores;
            }}

            onSubmit={(valores, { resetForm }) => {
                enviarCorreo(valores)
                    .then(() => {
                        console.log("Intengo de envio de email");
                    })
                    .then(() => {
                        resetForm();
                        cambiarFormulario(true);
                        setTimeout(() => cambiarFormulario(false), 3000);
                    })
                    .catch(error => {
                        console.error("Error en onSubmit:", error);
                        // Maneja el error aquí si es necesario
                    });
            }}
        >
            {( { errors } ) => (
                <Form className='form-container' id="formularioPieDePagina">
                    <h3 className='titulo-form-modal1'>COMUNICATE CON NOSOTROS</h3>
                    <div className='divsForm'>
                        <div>
                            <Field 
                                className='form-input1' 
                                type='text' id='nombre' 
                                name='nombre' 
                                placeholder='NOMBRE'
                            />
                            <ErrorMessage name='nombre' component={() => (
                                <div className='form-error'>{errors.nombre}</div>)}
                            />
                        </div>
                        <div>
                            <Field 
                                className='form-input2' 
                                type='empresa' 
                                id='empresa' 
                                name='empresa' 
                                placeholder='EMPRESA'
                            />
                            <ErrorMessage name='empresa' component={() => (
                                <div className='form-error'>{errors.empresa}</div>)}
                            />
                        </div>
                    </div>
                    <div className='divsForm'>
                        <div>
                            <Field 
                                className='form-input3' 
                                type='correo' 
                                id='correo' 
                                name='correo' 
                                placeholder='EMAIL'
                            />
                            <ErrorMessage name='correo' component={() => (
                                <div className='form-error'>{errors.correo}</div>)}
                            />
                        </div>
                        <div>
                            <Field 
                                className='form-input4' 
                                type='telefono' 
                                id='telefono' 
                                name='telefono' 
                                placeholder='TELEFONO'
                            />
                            <ErrorMessage name='telefono' component={() => (
                                <div className='form-error'>{errors.telefono}</div>)}
                            />
                        </div>
                    </div>
                    <div className='divsFormObs'>
                        <Field 
                            className='form-inputobs' 
                            type='observaciones' 
                            id='observaciones' 
                            name='observaciones' 
                        />
                        <ErrorMessage name='observaciones' component={() => (
                            <div className='form-error'>{errors.observaciones}</div>)}
                        />
                    </div>
                    <div className='divFormBtn'>
                        <button className='form-button' type='submit'><span className='texto-BtnForm'>Enviar</span></button>
                    </div>
                    {formularioEnviado && <p className='form-success'>Formulario enviado con exito!</p>}
                </Form>
            )}
        </Formik>
        </div>
        <Mapa/>
        <ImagenContacto/>
        <div className='contenedor-logoWSP'>
            <button onClick={handleRedirection} className='btn-WSP'>
                <img className='imagenLogoWSP' src='/images/logoWSP.svg' alt=''/>
            </button>
        </div>
        {modal && (<ModalCV closeModal={toggleModal} />)}
    </>
  );
}

export default Formulario;