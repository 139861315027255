import React, { useState } from 'react';

function CollapsibleText() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='expandText-btn'> 
      <button onClick={toggleText} className='boton-redondo'>
        <img className={ isExpanded ? 'icon2' : 'icon'} src='/images/flechita.svg' alt=''/>
      </button>
      {isExpanded && (
          <div className='contenedor-expandible'>
            <div>
              <img className='imagenExpandible' src='/images/imagenFachada.jpg' alt=''/>
            </div>
            <div className='text-expandible'>
              <p>
                Somos una empresa familiar con más de 15 años <br/>
                de experiencia dedicada a soluciones integrales <br/>
                en el ámbito de la higiene. Brindando servicios en la <br/>
                Provincia de Córdoba (Río Cuarto y alrededores) <br/>
                y en la Provincia de San Luis (Capital).
              </p>
            </div>
          </div>
      )}
    </div>
  );
}

export default CollapsibleText;
