import React from 'react';
import Slider from 'react-slick';
import './css/App.css';

function Carousel(){

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        centerPadding: '20px',
        autoplay: true,
        autoplaySpeed: 2000
    };

    return (
        <div className='divSlider'>
            <Slider {...settings} className='slider'>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img1.jpg' alt=''/>
                </div>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img2.jpg' alt=''/>
                </div>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img3.jpg' alt=''/>
                </div>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img4.jpg' alt=''/>
                </div>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img5.jpg' alt=''/>
                </div>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img6.jpg' alt=''/>
                </div>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img7.jpg' alt=''/>
                </div>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img8.jpg' alt=''/>
                </div>
                <div className='carousel-item'>
                    <img className='card-img' src='/images/Img9.jpg' alt=''/>
                </div>
            </Slider>
        </div>        
    )
}

export default Carousel;