import React, { useState } from 'react';
import ModalCV from './ModalCV';
import './css/App.css';

function Home({ onOpen }) {

    const [modalCV, setModalCV] = useState(false);
    const toggleModalCV = () => {
        setModalCV(!modalCV);
    };

    const scrollToForm = () => {
        const formElement = document.getElementById('formularioPieDePagina');
        if (formElement) {
          formElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='fondoPrincipal'>
            <div className='textos-Home'>
                <div className='logoHome'>
                    <img
                        src = '/images/logoHome.svg'
                        alt=''
                        className='logoAromas' />
                    <h1 className='text-logoHome'>Aromas</h1>
                </div>
                <h3 className='textoHome-Mayu'>
                    SERVICIO DE <br/>
                    LIMPIEZA PROFESIONAL <br/>
                    PARA EMPRESAS <br/>
                </h3>
                <p className='textoHome-Minu'>
                    Descubre el secreto detrás de entornos empresariales impecables. <br/>
                    Nuestros servicios de limpieza elevan tu espacio de <br/>
                    trabajo a un nuevo nivel. <br/>
                    ¡Conócenos más y transforma tu ambiente laboral hoy! <br/>
                </p>
                <div className='alineacion-boton'>
                    <button onClick={scrollToForm} className='boton-contactanos'><span className='texto-contactanos'>Contactanos</span></button>
                </div>
                
            </div>
            <div className='imagenHome-container'>
                <div className='alineacion-botonCV'>
                    <button onClick={onOpen} className='boton-contactanosCV'><span className='texto-contactanos'>Trabajá con nosotros</span></button>
                </div>
                <img
                src = '/images/imagenBalde.jpg'
                alt=''
                className='imagen-limpHome' />
            </div>
        </div>
        
    );
}

export default Home;