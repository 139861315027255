import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './css/App.css';

function ModalCV({ closeModal }) {
  const [formularioEnviado, cambiarFormulario] = useState(false);

  const [emailData, setEmailData] = useState({
    apiKey: '52364EB909C88334F9165B309F198EEC2CB316F33F828E96CDCE52FD8A3078FA790873816B9D565F0874CFD145FE896A',
    from: 'federicooses94@gmail.com',
    to: 'empleos@aromaslimpieza.ar',
    subject: 'Contacto Aromas Landing Page',
    bodyHtml: '',
    isTransactional: false
});

const construirCuerpoCorreo = (valores) => {
    var cuerpoHtml = '<h1>Información del formulario</h1>';
    cuerpoHtml += '<p><strong>Nombre:</strong> ' + valores.nombre + '</p>';
    cuerpoHtml += '<p><strong>Correo:</strong> ' + valores.correo + '</p>';
    cuerpoHtml += '<p><strong>Empresa:</strong> ' + valores.empresa + '</p>';
    cuerpoHtml += '<p><strong>Teléfono:</strong> ' + valores.telefono + '</p>';
    cuerpoHtml += '<p><strong>Observaciones:</strong> ' + valores.observaciones + '</p>';
    cuerpoHtml += '<p><strong>Observaciones:</strong> Contacto de Trabajo AROMAS </p>';
    
    console.log("CuerpoArmado");
    console.log(cuerpoHtml);

    return new Promise((resolve, reject) => {
        resolve(cuerpoHtml);
    });
};

  const enviarCorreo = async (valores) => {
    try {
        const cuerpoHtml = await construirCuerpoCorreo(valores);
        const emailDataToSend = {
            ...emailData,
            bodyHtml: cuerpoHtml
        };
        const response = await fetch('https://api.elasticemail.com/v2/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(emailDataToSend).toString()
        });
        
        if (response.ok) {
            console.log('Correo enviado con éxito.');
        } else {
            console.error('Error al enviar el correo:', response.statusText);
        }
    } catch (error) {
        console.error('Error al enviar el correo:', error);
    }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
    };

  return (
    <>
        <div className='contenedor-fullCV'>
            <div className='overlay' onClick={handleOverlayClick}>
                <div className='container-modal'>
                <img className='modal-imageMODALCV' src='/images/copiaCortada.png' alt=''/>
                <h2 className='modal-tituloCV'>Trabajá con nosotros</h2>
                <p className='parrafo-modalCV'>
                    ¡Unite a nuestro equipo de expertos en limpieza! En Aromas limpieza integral, estamos <br/>
                    constantemente en busca de talento comprometido y apasionado por brindar un servicio de <br/>
                    calidad a nuestros clientes. <br/>
                    Si sos una persona proactiva, responsable y te gusta trabajar en un entorno dinámico, ¡nos encan- <br/>
                    taría conocerte! En nuestra empresa, valoramos el esfuerzo, la dedicación y el trabajo en equipo. <br/>
                    Completá el formulario y nos pondremos en contacto con vos.
                </p>
                <div className='footer-contentMODALCV'>
                    <Formik
                        initialValues={{
                            nombre: '',
                            correo: '',
                            empresa: '',
                            telefono: '',
                            observaciones: ''
                        }}
                        validate={(valores) => {
                            let errores = {};

                            if(!valores.nombre){
                                errores.nombre = 'Por favor ingrese un nombre'
                            } else  if(!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.nombre)){
                                errores.nombre = 'El nombre solo puede contener letras y espacios'
                            }

                            if(!valores.correo){
                                errores.correo = 'Por favor ingrese un correo electrónico'
                            } else if(!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.correo)){
                                errores.correo = 'Por favor ingrese un correo electrónico válido'
                            }

                            if(!valores.empresa){
                                errores.empresa = 'Por favor ingrese una empresa'
                            }

                            if(!valores.telefono){
                                errores.telefono = 'Por favor ingrese un teléfono'
                            } else  if(!/^\d+$/.test(valores.telefono)){
                                errores.telefono = 'El teléfono solo puede tener números'
                            }

                            return errores;
                        }}

                        onSubmit={(valores, { resetForm }) => {
                            enviarCorreo(valores)
                                .then(() => {
                                    console.log("Intento de envio de email");
                                })
                                .then(() => {
                                    resetForm();
                                    cambiarFormulario(true);
                                    setTimeout(() => cambiarFormulario(false), 3000);
                                    closeModal()
                                })
                                .catch(error => {
                                    console.error("Error en onSubmit:", error);
                                    // Maneja el error aquí si es necesario
                                });
                        }}
                    >
                        {( { errors } ) => (
                            <Form className='form-containerMODAL' id="formularioPieDePagina">
                                <h3 className='titulo-form-modal1MODAL'>COMUNICATE CON NOSOTROS</h3>
                                <div className='divsFormMODAL'>
                                    <div>
                                        <Field 
                                            className='form-input1MODAL' 
                                            type='text' id='nombre' 
                                            name='nombre' 
                                            placeholder='NOMBRE'
                                        />
                                        <ErrorMessage name='nombre' component={() => (
                                            <div className='form-errorMODAL'>{errors.nombre}</div>)}
                                        />
                                    </div>
                                    <div>
                                        <Field 
                                            className='form-input2MODAL' 
                                            type='empresa' 
                                            id='empresa' 
                                            name='empresa' 
                                            placeholder='EMPRESA'
                                        />
                                        <ErrorMessage name='empresa' component={() => (
                                            <div className='form-errorMODAL'>{errors.empresa}</div>)}
                                        />
                                    </div>
                                </div>
                                <div className='divsFormMODAL'>
                                    <div>
                                        <Field 
                                            className='form-input3MODAL' 
                                            type='correo' 
                                            id='correo' 
                                            name='correo' 
                                            placeholder='EMAIL'
                                        />
                                        <ErrorMessage name='correo' component={() => (
                                            <div className='form-errorMODAL'>{errors.correo}</div>)}
                                        />
                                    </div>
                                    <div>
                                        <Field 
                                            className='form-input4MODAL' 
                                            type='telefono' 
                                            id='telefono' 
                                            name='telefono' 
                                            placeholder='TELEFONO'
                                        />
                                        <ErrorMessage name='telefono' component={() => (
                                            <div className='form-errorMODAL'>{errors.telefono}</div>)}
                                        />
                                    </div>
                                </div>
                                <div className='divsFormObsMODAL'>
                                    <Field 
                                        className='form-inputobsMODAL' 
                                        type='observaciones' 
                                        id='observaciones' 
                                        name='observaciones'
                                        placeholder='¡Escribenos tu mensaje!'
                                    />
                                    <ErrorMessage name='observaciones' component={() => (
                                        <div className='form-errorMODAL'>{errors.observaciones}</div>)}
                                    />
                                </div>
                                <div className='divFormBtnMODAL'>
                                    <button className='form-buttonMODAL' type='submit'><span className='texto-BtnFormMODAL'>Enviar</span></button>
                                </div>
                                {formularioEnviado && <p className='form-successMODAL'>Formulario enviado con exito!</p>}
                            </Form>
                        )}
                    </Formik>
                    </div>
                </div>
            </div>
        </div>
    </>
    
    
  );
}

export default ModalCV;