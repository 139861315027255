import React from 'react';
import './css/App.css';

function Mapa() {
    return (
        <div>
            <div className='cont-mapa'>
                <iframe title="Mapa Aromas" className='mapapa' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3341.5230712993844!2d-64.35441592352682!3d-33.121620180748984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d20012e070b19d%3A0x4b0dc2742a4bf35a!2sDe%C3%A1n%20Funes%20387%2C%20X5800%20DHG%2C%20C%C3%B3rdoba!5e0!3m2!1ses-419!2sar!4v1709992762181!5m2!1ses-419!2sar" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}

export default Mapa;