import React from 'react';
import './css/App.css';

function VideoTexto() {
    return (
        <div className='contenedor-videoytexto'>
            <iframe
                className='contenedor-video'
                src="https://www.youtube.com/embed/ALCbuDvJf9s" // URL del video de YouTube (reemplaza "TU_ID_DE_VIDEO" con el ID de tu video)
                title="Video de YouTube"
                frameborder="0" // Borde del reproductor (0 para no mostrar borde)
                allowfullscreen
            ></iframe>
            <p className='text-textvideo'>
                Aromas está estructurada para ofrecer a sus clientes toda una gama <br/>
                de servicios de limpieza para medianos y grandes espacios
            </p>
        </div>
    );
}

export default VideoTexto;